import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import { Feature } from '../components/feature';
import { Logo, LogoPiart, LogoPolicy, LogoSK, LogoSKVertical, LogoSNLab, LogoVertical } from '../components/logo';
import {
  MenuIcon,
  MenuCloseIcon,
  CommLabIcon,
  LinkedIcon,
  MailIcon,
  DownIcon,
  FbIcon,
  AtIcon,
  PhoneIcon,
  MapIcon,
  PlusIcon,
  DocsIcon,
} from '../components/icons';
import { start } from '../components/snow';
import { throttle } from '../components/utils';

import '../styles/style.scss';
import { references } from '../components/references';

function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: true,
  };
  const opt = {
    ...defaultOptions,
    ...options,
  };
  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
  if (!browserLocales) {
    return undefined;
  }
  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
}

function scrollToTargetAdjusted(element) {
  const headerOffset = 50;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
}

// data
const menu = [
  {
    title: 'Home',
    link: '/case_studies/',
  },
  {
    title: 'Reference',
    link: '/reference',
  },
  {
    title: 'Vedenie',
    link: '/vedenie',
  },
  {
    title: 'Kontakty',
    link: '/kontakt',
  },
];

const features = [
  {
    title: 'Public relations',
    symbol: 'Pr',
    number: '59',
    size: 'lg',
  },
  {
    title: 'Sociálne médiá',
    symbol: 'Sm',
    number: '62',
    size: 'sm',
  },
  {
    title: 'Mediálne tréningy',
    symbol: 'Mt',
    number: '109',
    size: 'sm',
  },
  {
    title: 'Krízová komunikácia',
    symbol: 'K',
    number: '19',
    size: 'mid',
  },
  {
    title: 'Eventy',
    symbol: 'Es',
    number: '99',
    size: 'sm',
  },
  {
    title: 'Interná komunikácia',
    symbol: 'I',
    number: '53',
    size: 'mid',
  },
  {
    title: 'Policy PR',
    symbol: 'Po',
    number: '84',
    size: 'sm',
  },
  {
    title: 'Strategická komunikácia',
    symbol: 'Sk',
    number: '64',
    size: 'sm',
  },
  {
    title: 'Public Affairs',
    symbol: 'Pa',
    number: '91',
    size: 'sm',
  },
  {
    title: 'Grafický dizajn',
    symbol: 'Gd',
    number: '65',
    size: 'sm',
  },
  {
    title: 'Foto/video',
    symbol: 'F',
    number: '9',
    size: 'sm',
  },
  {
    title: 'Weby',
    symbol: 'W',
    number: '74',
    size: 'sm',
  },
  {
    title: 'Podcasty',
    symbol: 'P',
    number: '15',
    size: 'sm',
  },
  {
    title: 'Influenceri',
    symbol: 'In',
    number: '49',
    size: 'sm',
  },
];

const caseStudies = [
  {
    links: [
      {
        title: 'Suppliers Day',
        link: '/case_studies/WEC_CZ.pdf',
      },
      {
        title: 'Profilace',
        link: '/case_studies/WEC_CZ_profilace.pdf',
      },
    ],
    title: 'Westinghouse',
    logo: '',
  },
  {
    links: [
      {
        title: 'Prípadová štúdie',
        link: '/case_studies/D.A.S. právní ochrana_CZ.pdf',
      },
    ],
    title: 'D.A.S.',
    logo: '/references/das.png',
  },
  {
    links: [
      {
        title: 'Prípadová štúdie',
        link: '/Wolt_CZ.pdf',
      },
    ],
    title: 'Wolt',
    logo: '',
  },
  {
    links: [
      {
        title: 'Prípadová štúdie',
        link: '/case_studies/Halla_CZ.pdf',
      },
    ],
    title: 'Halla',
    logo: '',
  },
  // {
  //   links: [
  //     {
  //       title: 'Business makeovers',
  //       link: '/case_studies/Meta_CZ_Business_makeovers.pdf',
  //     },
  //     {
  //       title: 'Creators of Tomorrow',
  //       link: '/case_studies/Meta_CZ_Creators_of_Tomorrow.pdf',
  //     },
  //     {
  //       title: 'Moje místo',
  //       link: '/case_studies/Meta_CZ_Moje_místo.pdf',
  //     },
  //   ],
  //   title: 'Meta',
  //   logo: '',
  // },
  {
    links: [
      {
        title: 'Prípadová štúdie',
        link: '/case_studies/GDELS_CZ.pdf',
      },
    ],
    title: 'GDELS',
    logo: '',
  },
  {
    links: [
      {
        title: 'Prípadová štúdie',
        link: '/case_studies/Moon50_CZ.pdf',
      },
    ],
    title: 'Moon50',
    logo: '',
  },
  {
    links: [
      {
        title: 'Prípadová štúdie',
        link: '/case_studies/ES_CZ.pdf',
      },
    ],
    title: 'Ekonomické stavby',
    logo: '',
  },
];

const Menu = ({ visible, toggle, toggleVisible, scrolling, aboutRef, referencesRef, contactRef }) => {
  return (
    <>
      <MenuIcon
        onClick={() => {
          toggle(false);
          toggleVisible(true);
          console.log('Toggle menu', visible);
        }}
      />
      {scrolling && (
        <nav className={`menu ${scrolling ? 'visible' : ''}`}>
          <div className="menu-logo">
            <LogoSK alt="Commlab" />
            <LogoSKVertical alt="Commlab" />
          </div>
          <ul className={`${visible ? 'visible-items' : ''}`}>
            <li
              className="grow2"
              onClick={() => {
                scrollToTargetAdjusted(aboutRef.current);
                toggle(false);
                toggleVisible(false);
                // aboutRef.current.scrollIntoView();
              }}
            >
              O Commlabu
            </li>
            <li
              className="grow2"
              onClick={() => {
                scrollToTargetAdjusted(referencesRef.current);
                toggle(false);
                toggleVisible(false);
                // referencesRef.current.scrollIntoView();
              }}
            >
              Referencie
            </li>
            <li
              className="grow2"
              onClick={() => {
                scrollToTargetAdjusted(contactRef.current);
                toggle(false);
                toggleVisible(false);
                // contactRef.current.scrollIntoView();
              }}
            >
              Kontakty
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

const Li = ({ href }) => {
  return (
    <Link target="_blank" className="icon linked grow" href={href}>
      <LinkedIcon />
    </Link>
  );
};

const Mail = ({ href }) => {
  return (
    <Link target="_blank" className="icon mail grow" href={href}>
      <MailIcon />
    </Link>
  );
};

const availableLangs = ['en', 'cs'];

// markup
const IndexPage = () => {
  const aboutRef = useRef(null);
  const referencesRef = useRef(null);
  const contactRef = useRef(null);
  const [lang, toggleLang] = useState('cs');
  const [scrolling, setScrolling] = useState(false);
  const [expandRef, setExpandRef] = useState(false);
  const [started, setStarted] = useState(false);
  // const [scrollTop, setScrollTop] = useState(0);
  const [visible, toggle] = useState(false);

  useEffect(() => {
    function onScroll() {
      if (visible) {
        return;
      }
      console.log('on scroll');
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      if (currentPosition > 100) {
        // downscroll code
        if (!scrolling) {
          setScrolling(true);
          console.log('on scroll over');
        }
      } else {
        // upscroll code
        if (scrolling) {
          setScrolling(false);
          console.log('on scroll below');
        }
      }
      // setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    const throttledOnSroll = throttle(onScroll, 300);

    window.addEventListener('scroll', throttledOnSroll);

    return () => window.removeEventListener('scroll', throttledOnSroll);
  });

  useEffect(() => {
    // Update the document title using the browser API
    const locales = getBrowserLocales();
    // Start animation
    if (!started) {
      start();
      setStarted(true);
    }

    // if (lang === locales[0]) {
    //   return;
    // } else if (locales[0] === 'en') {
    //   window.location.href = '/en';
    // }
    //
  });

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'sk',
        }}
      >
        <script charset="UTF-8" data-cs-lang="sk" src="//cdn.cookie-script.com/s/0b1a573414b0531f46d61fed9cf8d65c.js" />
      </Helmet>
      <main className={`${scrolling ? 'scrolled' : ''}`}>
        <div id="snow"></div>
        <Menu
          toggle={(next) => {
            setScrolling(!next);
          }}
          toggleVisible={(next) => {
            toggle(next);
          }}
          visible={visible}
          scrolling={scrolling}
          aboutRef={aboutRef}
          referencesRef={referencesRef}
          contactRef={contactRef}
        />
        <section ref={aboutRef} className="section landing">
          <article>
            <h1>
              <LogoSK alt="Commlab" />
            </h1>
            <p>
              Sme agentúra zameraná na oblasti public relations a public affairs.
              <br />
              Pôsobíme v Prahe, Brne a v Bratislave.
            </p>
          </article>
          {!scrolling && <DownIcon className="down-icon bounce" />}
        </section>
        <section className="commlab-group">
          <article>
            <Container>
              <Row>
                <Col md={12} lg={6} className="cl-left">
                  <p>
                    Od roku 2015 pomáhame globálnym spoločnostiam, českým a slovenským firmám a jednotlivcom obhajovať
                    ich záujmy vo verejnom priestore. Spoločne máme desaťročia skúseností s prácou na všetkých
                    úrovniach, od medzinárodnej až po lokálnu. Pracujeme podľa najvyšších profesionálnych a etických
                    štandardov.
                  </p>
                </Col>
                <Col md={12} lg={6} className="cl-right">
                  <p>
                    Zameriavame sa na klientov z oblasti technológií, priemyslu, vývoja, dizajnu a zdravotníctva. Naším
                    cieľom je stať sa obchodným partnerom našich klientov a pomáhať im ďalej rásť a posilňovať ich
                    pozíciu na trhu. Preto vždy hľadáme individuálne a kreatívne riešenia šité na mieru konkrétnym
                    projektom. Záujmy klienta sú alfou a omegou našej práce.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <br />
                  <p>
                    <em>Boli sme ocenení 13 prestížnymi cenami v oblasti PR a&nbsp;komunikácie.</em>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ul className="awards">
                    <li>
                      <img src="/lemuri_trans.png" />
                    </li>
                    <li>
                      <img src="/partners/zlaty_strednik.png" />
                    </li>
                    <li>
                      <img src="/lupa.png" />
                    </li>
                    <li>
                      <img src="/podcast_roku_trans.png" />
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </article>
          <h3>Commlab.sk patrí ku skupine Commlab.cz</h3>
          <ul className="group-logos">
            <li>
              <a href="https://commlab.cz/">
                <Logo />
              </a>
            </li>
          </ul>
          <h3>Ďalšie prvky v našom laboratóriu</h3>
          <ul className="group-logos-2">
            <li className="piart">
              <a href="https://piartlab.cz/">
                <LogoPiart fill="white" />
              </a>
            </li>
            <li className="policy">
              <a href="https://policylab.cz/">
                <LogoPolicy fill="white" />
              </a>
            </li>
            <li className="snlab">
              <a href="https://socialnetworkslab.cz">
                <LogoSNLab fill="white" />
              </a>
            </li>
          </ul>
        </section>
        <section className="features">
          <div className="wrap" style={{}}>
            {features.map(({ title, symbol, number, size }, i) => (
              <Feature
                key={i}
                title={title}
                symbol={symbol}
                number={number}
                className={`feature-${number}`}
                size={size}
              />
            ))}
          </div>
        </section>
      </main>
      <section ref={referencesRef} className="references">
        <h2>Referencie</h2>
        <Container>
          <Row>
            <Col>
              <ul>
                {[...references.slice(0, 7)].map((ref, i) => (
                  <li key={i} className="reference">
                    <img src={ref.link} alt={ref.title} />
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul className={expandRef ? 'more-ref visible' : 'more-ref'}>
                {[...references.slice(7, references.length)].map((ref, i) => (
                  <li key={i} className="reference">
                    <img src={ref.link} alt={ref.title} />
                  </li>
                ))}
              </ul>
              {!expandRef && <PlusIcon onClick={() => setExpandRef(!expandRef)} />}
            </Col>
          </Row>
        </Container>
        <h2>Prípadové štúdie</h2>
        <Container>
          <Row>
            {caseStudies.map((item, i) => (
              <Col lg={3} md={4} sm={12} key={i}>
                <div className="case-study">
                  <h4>{item.title}</h4>
                  <ul>
                    {item.links.map((item, i) => {
                      return (
                        <li>
                          <DocsIcon />
                          &nbsp;
                          <a target="_blank" href={item.link}>
                            {item.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
        <Container></Container>
      </section>
      <section ref={contactRef} className="leads">
        <h2>Vedenie</h2>
        <Container>
          <Row>
            <Col md={2} />
            <Col md={4}>
              <div className="lead">
                <div className="frame">
                  <Li href="https://www.linkedin.com/in/silvia-hud%C3%A1%C4%8Dkov%C3%A1-b13b141a" />
                  <Mail href="mailto:silvia@commlab.cz" />
                  <img src="/silva.png" alt="Silvia Hudáčková" />
                </div>
                <div className="title">
                  <span className="name">Silvia Hudáčková</span>
                  <br />
                  <span className="role">Managing Partner Commlab.sk</span>
                  <br />
                  <span className="email">
                    <Link href="mailto:silvia@commlab.sk">silvia@commlab.sk</Link>
                  </span>
                  <br />
                  <span className="phone">
                    <Link href="tel:+421907053346">+421 907 053 346</Link>
                  </span>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="lead">
                <div className="frame">
                  <Li href="https://www.linkedin.com/in/tom%C3%A1%C5%A1-klva%C5%88a-ph-d-19601a13a/" />
                  <Mail href="mailto:tomas@commlab.cz" />
                  <img src="/tomas.png" alt="Tomáš Klvaňa" />
                </div>
                <div className="title">
                  <span className="name">Tomáš Klvaňa</span>
                  <br />
                  <span className="role">Managing Partner Commlab.sk</span>
                  <br />
                  <span className="email">
                    <Link href="mailto:tomas@commlab.sk">tomas@commlab.sk</Link>
                  </span>
                  <br />
                  <span className="phone">
                    <Link href="tel:+420734719889">+420 734 719 889</Link>
                  </span>
                </div>
              </div>
            </Col>
            <Col md={2} />
          </Row>
        </Container>
        <div className="contact">
          <Container>
            <Row>
              <Col>
                <h3>Kontakty</h3>
                <p>Chcete sa stretnúť alebo nám napísať?</p>
              </Col>
            </Row>
            <Row className="contactFrame">
              <Col lg={2}></Col>
              <Col lg={4}>
                <p>
                  <AtIcon /> <Link href="mailto:silvia@commlab.sk">silvia@commlab.sk</Link> <br />
                  <PhoneIcon /> <Link href="tel:+421907053346">+421 907 053 346</Link> <br />
                  <br />
                  <AtIcon /> <Link href="mailto:tomas@commlab.sk">tomas@commlab.sk</Link> <br />
                  <PhoneIcon /> <Link href="tel:+420734719889">+420 734 719 889</Link> <br />
                </p>
                <p>
                  <FbIcon href="https://www.facebook.com/commlabcz/" />
                  <Li href="https://www.linkedin.com/company/commlab/" />
                </p>
              </Col>
              <Col lg={4}>
                <p>
                  <MapIcon />
                  <Link target="_blank" href="https://maps.app.goo.gl/nWFkCSJPBYSSHsCJ9">
                    Bottova 2/A, 811&nbsp;09 Bratislava
                  </Link>
                  <br />
                  <br />
                  <strong>Fakturační adresa:</strong>
                  <br />
                  Ružová dolina 609/12
                  <br />
                  Bratislava - mestská časť Ružinov 821 09
                  <br />
                  <br />
                  <strong>IČO:</strong>&nbsp; 56380291
                  <br />
                  <br />
                  <strong>Jazyk</strong> <Link href="/">SK</Link> | <Link href="/en">EN</Link>
                </p>
              </Col>
              <Col lg={2}></Col>
            </Row>
          </Container>
        </div>
      </section>
      <footer>© {`${new Date().getFullYear()}`} Communication Lab Slovakia s. r. o. Všetky práva vyhradené.</footer>
    </>
  );
};

export default IndexPage;
